export const injectTargetBlank = (html: string): string => {
    const anchorRegex = /<a(\s[^>]*)?>/g;

    const replaceAnchor = (match: string) => {
        if (!match.includes('target="_blank"')) {
            return match.replace('<a', '<a target="_blank" ');
        }

        return match;
    };

    const modifiedHTML = html.replace(anchorRegex, replaceAnchor);

    return modifiedHTML;
};
